import React, { useEffect, useState } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';
import PropTypes from 'prop-types';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import { StyledHeader } from '../../styles/Header';
import { approvedScreenRegisterViewEvent, ccRatesEvent, creditCardRTREvent } from '../../../../tracking';
import { useDynamicYieldValues } from '../../../../../../utils/dynamicYield';
import { useRegistrationFormContext } from '../../../RegistrationForm/RegistrationForm';
import { generateBranchLink } from '../../../../../../utils/branchIO/generateBranchLink';
import { getPublishedOffers } from '../../../../../../domains/prospect/publishedOffers';

import CommsReminder from './CommsReminder';

const StyledTitle = styled(Typography)`
  font-size: 28px;

  @media (width >= 768px) {
    font-size: 2.1rem;
  }
`;

const StyledBox = styled(Box)`
  gap: 8px;
  
  p {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
`;

const RateBox = styled(Box)`
  background-color: #820AD1;  ;
  border-radius: 1px 1px 25px 25px;
  margin-top: -24px;
  margin-bottom: 25px;
  padding: 8px;
`;

const CC_RATES_XP_ID = '348839';
const CC_RATES_XP_VARIANT_1_ID = '48899';

function MultiproductMagic({ publishedOffersLink }) {
  const { email, phone, closeRegistrationForm } = useSiteContext();
  const { values: dynamicYieldValues } = useDynamicYieldValues();
  const { prospectRegistrationData } = useRegistrationFormContext();
  const [rates, setRates] = useState(null);

  // CC Rates Experiment
  const ccRatesVariant = useFigPiiExperiment(CC_RATES_XP_ID);

  useEffect(() => {
    approvedScreenRegisterViewEvent();
    creditCardRTREvent();
  }, []);

  const navigateToMagicLink = () => {
    window.open(generateBranchLink({ prospectId: prospectRegistrationData.prospectId }), '_blank'); // Opens in a new tab
  };

  const handleGetPublishedOffers = async () => {
    const response = await getPublishedOffers(publishedOffersLink);

    const skuToMatch = 'mx-credit-card-moradita';
    const valueToMatch = 'revolving-interest-rate';
    let interestRate = 0;

    response.offers.forEach(offer => {
      if (offer.sku === skuToMatch) {
        const meta = offer?.metadata?.find(metadata => metadata.key === valueToMatch);

        if (meta && meta.value) {
          interestRate = parseFloat(meta.value);
        }
      }
    });

    if (!interestRate || interestRate === 0 || Number.isNaN(interestRate)) {
      console.error('No interest rate found');
    } else {
      const percentage = (interestRate * 100).toFixed(2);
      setRates(percentage);
    }
  };

  useEffect(() => {
    if (publishedOffersLink && ccRatesVariant !== CC_RATES_XP_VARIANT_1_ID) {
      handleGetPublishedOffers();
      ccRatesEvent('rtr');
    }
  }, [publishedOffersLink, ccRatesVariant]);

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      {/* TITLE AND SUBTITLE */}
      <StyledTitle
        variant="heading2"
        marginTop="6x"
      >
        ¡Ya tenemos tu oferta lista en la app! 🥳️
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginTop="4x"
        whiteSpace="pre-line"
        strong
      >
        <Typography strong tag="span" variant="subtitle1" color="primary">
          Continúa
        </Typography>
        {' para terminar de contratar tu oferta.'}
      </Typography>

      <CommsReminder email={email} phone={phone} />

      {/* MAGIC LINK BUTTON */}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="4x"
        marginTop="8x"
      >
        <StyledButton
          iconProps={{ name: 'arrow-right' }}
          extended
          type="button"
          onClick={() => navigateToMagicLink()}
        >
          Continuar en la app
        </StyledButton>
      </Box>

      {/* IMAGE BLOCK */}

      <Typography variant="subtitle1" strong>
        Tu oferta te está esperando:
      </Typography>

      <StyledBox
        marginTop="6x"
        marginBottom="6x"
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingRight={{ xs: '24px', md: '48px' }}
        backgroundColor={rates ? '#F3EDFF' : 'white'}
        paddingVertical={rates ? '4x' : '0px'}
        borderRadius={rates ? '25px 25px 0px 0px' : '0px'}
      >
        <Image
          alt="Icono de una tarjeta"
          src="one-step/rtr/credit-card-magic.png"
          webp
          width="98px"
          height="99px"
        />

        <Typography variant="paragraph1" strong>
          Tarjeta de Crédito Nu
          <Typography variant="paragraph1">
            $0 anualidad, MSI y opciones de pago flexibles.
          </Typography>

        </Typography>
      </StyledBox>
      { rates && (
        <RateBox>
          <Typography variant="paragraph1" color="white" strong textAlign="center">
            {`¡Y una tasa de interés de ${rates}% solo para ti!`}
          </Typography>
        </RateBox>
      )}

      <StyledBox
        marginTop="2x"
        marginBottom="6x"
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingRight="60px"
        backgroundColor={rates ? '#F3EDFF' : 'white'}
        paddingVertical={rates ? '4x' : '0px'}
        borderRadius={rates ? '25px' : '0px'}
      >
        <Image
          alt="Icono de una tarjeta"
          src="one-step/rtr/debit-card-magic.png"
          webp
          width="98px"
          height="99px"
        />

        <Typography variant="paragraph1" strong>
          Cuenta Nu:
          <Typography variant="paragraph1" tag="span">
            {' '}
            Débito y en la app puedes crecer tus ahorros
            {' '}
          </Typography>
          {`${dynamicYieldValues.dynamicYield}% al año`}
        </Typography>
      </StyledBox>

      {/* CAT LINK */}

      <Box
        display="flex"
        justifyContent="left"
        marginTop="8x"
      >
        <Link
          href="/gat"
          variant="action"
          typographyProps={{ variant: 'paragraph1' }}
          target="_blank"
        >
          Conoce GAT Real y Nominal de Cuenta
        </Link>
      </Box>

    </FormStepContainer>
  );
}

MultiproductMagic.propTypes = {
  publishedOffersLink: PropTypes.string,
};

MultiproductMagic.defaultProps = {
  publishedOffersLink: '',
};

export default MultiproductMagic;
