import React, { useState, useEffect } from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';
import PropTypes from 'prop-types';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';
import { sendRegisterProspectEvent } from '@nubank/www-latam-commons/services/analytics';

import { getPolling, startRealtimeAnalysis } from '../../../../utils/prospect';
import { useRegistrationFormContext } from '../RegistrationForm/RegistrationForm';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { ERROR_SEVERITY, sentryException } from '../../../../utils/sentry';

import DeniedScreen from './DeniedScreen';
import DeniedScreenXp from './DeniedScreenXp';
import LoadingScreen from './LoadingScreen';
import CuentaOnlyMagic from './rtr/magic-link/CuentaOnlyMagic';
import SecuredCardMagic from './rtr/magic-link/SecuredCardMagic';
import MultiproductMagic from './rtr/magic-link/MultiproductMagic';

const RTR_DEFAULT_SCREEN_EXPERIMENT_ID = '350391';
const RTR_DEFAULT_SCREEN_EXPERIMENT_VARIANT = '51242';

const SCREENS = {
  APPROVED: 'APPROVED',
  NEUTRAL: 'NEUTRAL',
};

const DecisionStep = ({ activeFinancialGoals }) => {
  const { email } = useSiteContext();
  const { prospectRegistrationData } = useRegistrationFormContext();
  const [analisisResult, setAnalisisResult] = useState(false);
  const [analyzingPercentage, setAnalyzingPercentage] = useState(10);
  const [currentScreen, setCurrentScreen] = useState(null);

  const defaultScreenVariant = useFigPiiExperiment(RTR_DEFAULT_SCREEN_EXPERIMENT_ID);

  const updateRealtimeAnalysis = percentage => {
    setAnalyzingPercentage(percentage);
  };

  const handleProspectRegistration = async () => {
    const {
      hasThrottle,
      marketingId,
      hasRealtimeAnalysis,
      realtimeUrl,
      prospectType,
    } = prospectRegistrationData;

    try {
      if (hasThrottle) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      sendRegisterProspectEvent(prospectType, marketingId);

      if (!hasRealtimeAnalysis) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      const polling = await getPolling(realtimeUrl, activeFinancialGoals);

      if (!polling) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      const analisis = activeFinancialGoals ? polling
        : await startRealtimeAnalysis({
          url: realtimeUrl,
          onProgress: updateRealtimeAnalysis,
          polling,
        });

      updateRealtimeAnalysis(100);

      if (!analisis || !analisis.template) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      setAnalisisResult(analisis.template);

      if (analisis.template.id === 'no_products') {
        setTimeout(() => setCurrentScreen(SCREENS.NEUTRAL), 1200);
      } else {
        setTimeout(() => setCurrentScreen(SCREENS.APPROVED), 1200);
      }
    } catch (error) {
      setCurrentScreen(SCREENS.NEUTRAL);
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'decision step',
        namespace: '<DecisionStep />',
        level: ERROR_SEVERITY.CRITICAL,
      });
    }
  };

  return (
    <Form.Step isForm={false}>
      {({ formsValues }) => {
        useEffect(() => {
          handleProspectRegistration();
        }, []);

        if (currentScreen === 'APPROVED') {
        /// ////////////////////////// CUENTA ONLY////////////////////////////////////////
          if (analisisResult.id === 'savings_account') {
            return <CuentaOnlyMagic />;
          }

          /// ////////////////////////// SECURED CARD////////////////////////////////////////
          if (analisisResult.id === 'secured_credit_card_and_nuconta') {
            return <SecuredCardMagic />;
          }

          // ///////////////////////////// MULTIPRODUCT ////////////////////////////////////////
          return (
            <MultiproductMagic
              publishedOffersLink={prospectRegistrationData.publishedOffersLink}
            />
          );
        }

        /// ////////////////////////// DENIED/NEUTRAL ////////////////////////////////////////
        if (currentScreen === 'NEUTRAL') {
          if (defaultScreenVariant === RTR_DEFAULT_SCREEN_EXPERIMENT_VARIANT) {
            return (
              <DeniedScreenXp />
            );
          }

          return (
            <DeniedScreen name={formsValues.names} email={email} />
          );
        }

        /// ////////////////////////// LOADING ////////////////////////////////////////
        return (
          <LoadingScreen percentage={analyzingPercentage} />
        );
      }}
    </Form.Step>
  );
};

DecisionStep.defaultProps = {
  activeFinancialGoals: false,
};

DecisionStep.propTypes = {
  activeFinancialGoals: PropTypes.bool,
};

export default DecisionStep;
