import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import styled from 'styled-components';

import { useSiteContext } from '../../../../components/SiteContext/SiteContext';

const StyledBox = styled(Box)`
  margin-left: -8px;
  margin-right: -12px;
`;

const FormHeader = ({ currentStep, totalSteps, prevStep }) => {
  const { closeRegistrationForm } = useSiteContext();

  const isTheFirstStep = currentStep === 1;
  const isTheLastStep = currentStep === totalSteps;

  const createButtonRef = useRef(null);

  useEffect(() => {
    if (createButtonRef && createButtonRef.current) {
      createButtonRef.current.focus();
    }
  }, []);

  const handleOnClose = () => {
    closeRegistrationForm(true);
  };

  if (isTheLastStep) {
    return null;
  }

  return (

    <Box
      tag="header"
      display="flex"
      flexDirection="column"
      paddingHorizontal={{ xs: '4x', lg: '40px' }}
      paddingBottom="4x"
      paddingTop="2x"
      height="5rem"
    >
      <StyledBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div data-testid="prev-step-container">
          {!isTheFirstStep && (
            <Button
              variant="basic"
              styleVariant="black"
              onClick={prevStep}
              iconProps={{
                name: 'arrow-left', title: 'Regresar',
              }}
            />
          )}
        </div>

        <Button
          buttonRef={createButtonRef}
          data-testid="close-btn"
          variant="basic"
          styleVariant="black"
          onClick={handleOnClose}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
          size="default"
        />

      </StyledBox>
    </Box>

  );
};

FormHeader.propTypes = {
  currentStep: PropTypes.number.isRequired,
  prevStep: PropTypes.func.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default FormHeader;
