import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { saveCollectedData } from '@nubank/www-latam-commons/services/credolab';

import { getCredolabAPIPrivateKey, getCredolabUrl } from '../environmentUtils';
import { ERROR_SEVERITY, sentryException } from '../sentry';

export function useCredolabTracking() {
  const serviceRef = useRef(null);
  const trackingIdRef = useRef(null);
  const startedAtRef = useRef(null);

  const stopTracking = useCallback(async (prospectId, taxId) => {
    if (!serviceRef.current) return;
    try {
      await serviceRef.current.stopTrackingAsync();
      await serviceRef.current.stopTrackingAndCompleteAsync();
      if (prospectId && taxId && trackingIdRef.current && startedAtRef.current) {
        await saveCollectedData(prospectId, taxId, trackingIdRef.current, startedAtRef.current);
      }
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'stopTracking',
        namespace: 'useCredolabTracking',
        level: ERROR_SEVERITY.DEFAULT,
      });
    } finally {
      // Always clear the refs
      serviceRef.current = null;
      trackingIdRef.current = null;
      startedAtRef.current = null;
    }
  }, []);

  const startTracking = useCallback(async () => {
    // Avoid re-initialization if already tracking
    if (serviceRef.current) return;
    try {
      const { CredoAppServiceAsync } = await import('@credolab/credoapp-sdk');
      const authKey = getCredolabAPIPrivateKey();
      const url = getCredolabUrl();
      const externalId = uuid();

      const service = new CredoAppServiceAsync(url, authKey);
      await service.startTrackingAsync(externalId.toString());

      serviceRef.current = service;
      trackingIdRef.current = externalId;
      startedAtRef.current = new Date().toISOString();
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'startTracking',
        namespace: 'useCredolabTracking',
        level: ERROR_SEVERITY.DEFAULT,
      });
      await stopTracking(null);
    }
  }, [stopTracking]);

  // Cleanup on unmount
  useEffect(() => () => {
    if (serviceRef.current) {
      serviceRef.current.stopTrackingAsync();
    }
  }, []);

  return { startTracking, stopTracking };
}
