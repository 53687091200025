import React from 'react';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { StyledSubtitle } from './Styled';

function CreditBureauAuthDisclaimer() {
  return (
    <StyledSubtitle marginBottom="8x" whiteSpace="pre-line">
      Con el
      <Typography tag="span" strong> código que enviamos a tu celular </Typography>
      {' \n aceptas  que Nu realice '}
      <Link href="autorizacion-buro-de-credito">la consulta de buró.</Link>
    </StyledSubtitle>
  );
}

export default CreditBureauAuthDisclaimer;
