import React, { useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Image from '@nubank/nuds-web/components/Image/Image';
import Box from '@nubank/nuds-web/components/Box/Box';

import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { deniedScreenRegisterEvent } from '../../tracking';

const Title = styled(Typography)`
  font-size: 28px;
`;

const DeniedScreenXp = () => {
  const { closeRegistrationForm } = useSiteContext();

  const handleCloseBtn = () => {
    closeRegistrationForm(true);
  };

  useEffect(() => {
    deniedScreenRegisterEvent();
  }, []);

  return (
    <Box
      height="100%"
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        marginBottom="2x"
        padding="2x"
      >
        <Button
          variant="basic"
          styleVariant="primary"
          onClick={handleCloseBtn}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </Box>

      <Box
        paddingHorizontal={{ xs: '24px', lg: '40px' }}
      >

        <Image
          src="one-step/rtr/push-notification.svg"
          alt="Un icono de una notificación"
          webp={false}
          width="150px"
        />

        <Title marginTop="8x" variant="heading3">
          Te notificaremos
          <Title tag="span" variant="heading3" color="primary">
            {' '}
            cuando tu oferta esté lista 📩
          </Title>
        </Title>

        <Typography marginTop="4x" variant="subtitle1" maxWidth="300px">
          <Typography variant="subtitle1" strong tag="span">
            Te enviaremos un mensaje de WhatsApp y un correo
            {' '}
          </Typography>
          para que puedas abrir tu oferta en la app.
        </Typography>

      </Box>
    </Box>
  );
};

export default DeniedScreenXp;
