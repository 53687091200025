import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';

import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { ERROR_SEVERITY, sentryException } from '../../../../utils/sentry';
import { validateCandidateData } from '../../../../domains/redirection/flowRedirection';
import { redirectionEvent } from '../../tracking';
import { registerCandidateData } from '../../../../domains/candidate/candidate';

const StyledDrawer = styled(Drawer)`
  #new-rfc-drawer {
    bottom: 0;
    top: auto;
    height: 400px;
    border-radius: 16px 16px 0 0;
    padding: 12px;
  }
`;

const StyledTitle = styled(Typography)`
  font-size: 24px;
`;

const UpdateEmailBox = styled(Box)`
  svg {
    display: none;
  }

  input {
    border-bottom: ${props => (props.wasEmailUpdated ? '1px solid auto' : '1px solid #D72923')};
    caret-color: ${props => (props.wasEmailUpdated ? 'auto' : '#D72923')};
    color: ${props => (props.wasEmailUpdated ? 'auto' : '#D72923')};
  }
`;

const ErrorMessage = styled(Typography)`
  color: #D72923;
  font-size: 14px;
  font-weight: bold;
  margin-top: -30px;
`;

function NewRFC({
  setShowNewRFCModal, showNewRFCModal, setFieldValue,
  data, emailSuccessfullyUpdated,
}) {
  const {
    email, setEmail, discoveryUrlsList,
    registrationFlowEvents, updateRegistrationFlowEvents,
  } = useSiteContext();
  const { nextStep, formsValues } = data;

  const alreadyRegisteredEmails = [email];
  const [updatedEmailValue, setUpdatedEmailValue] = useState(email);
  const [wasEmailUpdated, setWasEmailUpdated] = useState(false);

  useEffect(() => {
    setFieldValue('updateEmail', email);
    redirectionEvent('mail');
  }, []);

  useEffect(() => {
    setWasEmailUpdated(!alreadyRegisteredEmails.includes(updatedEmailValue));
  }, [updatedEmailValue]);

  const handleSubmitBtn = async () => {
    redirectionEvent('mail sent');
    setFieldValue('email', updatedEmailValue);

    try {
      const payload = {
        phone: formsValues.phone,
        email: updatedEmailValue,
        taxId: formsValues.taxId,
        urls: discoveryUrlsList,
      };

      const response = await validateCandidateData(payload);

      // The mail is already used, user needs to update again
      if (response.next_screen === 'email_fallback_screen') {
        alreadyRegisteredEmails.push(updatedEmailValue);
        setWasEmailUpdated(!alreadyRegisteredEmails.includes(updatedEmailValue));
        redirectionEvent('mail used');
        return;
      }

      // The mail was updated successfully
      updateRegistrationFlowEvents({ ...registrationFlowEvents, redirectionHappyPath: true });
      setShowNewRFCModal(false);
      setEmail(updatedEmailValue);
      formsValues.email = updatedEmailValue;
      emailSuccessfullyUpdated({ nextStep, formsValues });
      redirectionEvent('mail default');

      // Create candidate
      await registerCandidateData(updatedEmailValue, discoveryUrlsList);
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'curp_feedback_redirection',
        namespace: '<CurpFeedbackRedirection />',
        level: ERROR_SEVERITY.ERROR,
      });
    }
  };

  return (
    <StyledDrawer
      width="100%"
      id="new-rfc-drawer"
      direction="bottom"
      desktopDirection="bottom"
      open={showNewRFCModal}
      onClose={() => setShowNewRFCModal(false)}
    >
      {({ DrawerContent, DrawerCloseButton }) => (
        <DrawerContent>
          <Box>
            <DrawerCloseButton onClick={() => setShowNewRFCModal(false)} />
          </Box>

          <Box
            paddingHorizontal="4x"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >

            <Box>
              <StyledTitle variant="heading4">
                Este correo ya está registrado
              </StyledTitle>
              <Typography variant="subtitle1" marginTop="2x" marginBottom="4x">
                Déjanos un nuevo correo para continuar tu registro
              </Typography>

              <UpdateEmailBox wasEmailUpdated={wasEmailUpdated}>
                <TextField
                  id="updateEmail"
                  name="updateEmail"
                  type="email"
                  label="Correo electrónico"
                  syncValidations={{
                    required: 'Escribe una dirección de correo válida',
                    email: 'Escribe una dirección de correo válida',
                  }}
                  onInput={e => setUpdatedEmailValue(e.target.value)}
                />
              </UpdateEmailBox>
              {
                !wasEmailUpdated && (
                <ErrorMessage variant="subtitle1">
                  Usa otro correo para continuar
                </ErrorMessage>
                )
              }

            </Box>

            <Box>
              <Button
                id="new-rfc-btn"
                type="button"
                variant="contained"
                styleVariant="primary"
                extended
                onClick={handleSubmitBtn}
                disabled={!wasEmailUpdated}
              >
                Continuar
              </Button>
            </Box>

          </Box>

        </DrawerContent>
      )}
    </StyledDrawer>
  );
}

NewRFC.propTypes = {
  data: PropTypes.shape({
    formsValues: PropTypes.objectOf(PropTypes.shape).isRequired,
    nextStep: PropTypes.func.isRequired,
  }).isRequired,
  emailSuccessfullyUpdated: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setShowNewRFCModal: PropTypes.func.isRequired,
  showNewRFCModal: PropTypes.bool.isRequired,
};

export default NewRFC;
