import React, { useEffect } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';

import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { cuentaRTREvent } from '../../../../tracking';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import { StyledHeader } from '../../styles/Header';
import { useRegistrationFormContext } from '../../../RegistrationForm/RegistrationForm';
import { generateBranchLink } from '../../../../../../utils/branchIO/generateBranchLink';

import CommsReminder from './CommsReminder';

const StyledTitle = styled(Typography)`
  font-size: 28px;

  @media (width >= 768px) {
    font-size: 2.1rem;
  }
`;

const Line = styled(Box)`
  border: 1px solid rgba(17 17 17 / 10%);
  margin-top: 5px;
  width: 97%;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
`;

function CuentaOnlyMagic() {
  const { email, phone, closeRegistrationForm } = useSiteContext();
  const { prospectRegistrationData } = useRegistrationFormContext();

  useEffect(() => {
    cuentaRTREvent();
  }, []);

  const navigateToMagicLink = () => {
    window.open(generateBranchLink({ prospectId: prospectRegistrationData.prospectId }), '_blank'); // Opens in a new tab
  };

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      <Image
        src="one-step/rtr/green-check.svg"
        alt="Ilustracion de un icono verde con una palomita"
        width="150px"
        webp={false}
      />

      {/* TITLE AND SUBTITLE */}
      <StyledTitle
        variant="heading2"
        marginTop="6x"
      >
        ¡Ya tenemos tu oferta lista en la app! 🥳️
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginTop="4x"
        whiteSpace="pre-line"
        strong
      >
        <Typography strong tag="span" variant="subtitle1" color="primary">
          Continúa
        </Typography>
        {' para terminar de contratar tu oferta.'}
      </Typography>

      <CommsReminder email={email} phone={phone} />

      {/* MAGIC LINK BUTTON */}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="4x"
        marginTop="8x"
      >
        <StyledButton
          iconProps={{ name: 'arrow-right' }}
          extended
          type="button"
          onClick={() => navigateToMagicLink()}
        >
          Continuar en la app
        </StyledButton>
      </Box>

      {/* BENEFITS LIST */}

      <Box marginBottom="4x" marginTop="2x">
        <Typography variant="heading4" strong color="primary">
          Qué beneficios te da Nu:
        </Typography>

        <Line tag="hr" />

        <Box
          marginTop="6x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de corazón"
            src="one-step/rtr/heart.svg"
            webp={false}
            width="24px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            marginTop="-5px"
            strong
          >
            Sin comisiones por uso mínimo.
          </Typography>
        </Box>

        <Box
          marginTop="6x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de carita feliz"
            src="one-step/rtr/smiley.svg"
            webp={false}
            width="24px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            marginTop="-2px"
            color="#000000A3"
          >
            Atención
            <Typography
              tag="span"
              strong
              variant="paragraph1"
              color="black"
            >
              {' '}
              humana y soporte las 24/7.
            </Typography>
          </Typography>
        </Box>

        <Box
          marginTop="6x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de escudo"
            src="one-step/rtr/shield.svg"
            webp={false}
            width="36px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            color="#000000A3"
          >
            Tecnología para
            <Typography
              tag="span"
              strong
              variant="paragraph1"
              color="black"
            >
              {' '}
              cuidar que solo tú apruebes
              {' '}
            </Typography>
            y sepas de cada transacción.
          </Typography>
        </Box>

      </Box>

    </FormStepContainer>
  );
}

export default CuentaOnlyMagic;
