import { isProduction } from '@nubank/www-latam-commons/utils/environmentUtils';

export const getConfig = () => ({
  credolabAPIPrivateKeyStaging: process.env.NEXT_PUBLIC_WWW_CREDOLAB_API_PRIVATE_KEY_STAGING,
  credolabAPIPrivateKeyProd: process.env.NEXT_PUBLIC_WWW_CREDOLAB_API_PRIVATE_KEY_PROD,
  credolabUrlStaging: process.env.NEXT_PUBLIC_WWW_URL_CREDOLAB_STAGING,
  credolabUrlProd: process.env.NEXT_PUBLIC_WWW_URL_CREDOLAB_PROD,
});

const config = getConfig();

export function getConfigValue(prodStrKey, stagingStrKey) {
  return isProduction() ? config[prodStrKey] : config[stagingStrKey];
}

export function getCredolabAPIPrivateKey() {
  return getConfigValue('credolabAPIPrivateKeyProd', 'credolabAPIPrivateKeyStaging');
}

export function getCredolabUrl() {
  return getConfigValue('credolabUrlProd', 'credolabUrlStaging');
}
