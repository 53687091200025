import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

function CommsReminder({ phone, email }) {
  return (
    <Typography color="#000000A3" variant="subtitle2" marginTop="6x">
      {'Si ya tienes la app continúa el registro con el correo '}
      <Typography tag="span" color="primary" strong variant="subtitle2">
        {email}
      </Typography>
      {' y el celular '}
      <Typography tag="span" color="primary" strong variant="subtitle2">
        {`** ****${phone.slice(8)}`}
      </Typography>
      .
    </Typography>
  );
}

CommsReminder.propTypes = {
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default CommsReminder;
